import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard/Default")));

//Product Route
const Product = Loadable(lazy(() => import("views/utilities/product")));
const EditProduct = Loadable(lazy(() => import("views/utilities/product/edit-product")));
const AddProduct = Loadable(lazy(() => import("views/utilities/product/add-product")));

// QR Route
const QR = Loadable(lazy(() => import("views/utilities/qr")));
const EditQR = Loadable(lazy(() => import("views/utilities/qr/edit-qr")));
const ViewQR = Loadable(lazy(() => import("views/utilities/qr/view-qr")));
const AddQR = Loadable(lazy(() => import("views/utilities/qr/add-qr")));
const HistoryQR = Loadable(lazy(() => import("views/utilities/qr/history-qr")));

const AllWithdraw = Loadable(
  lazy(() => import("views/utilities/withdraw/all-withdraw"))
);
const WithdrawRequest = Loadable(
  lazy(() => import("views/utilities/withdraw/withdraw-request"))
);
const EditWithdraw = Loadable(
  lazy(() => import("views/utilities/withdraw/edit-withdraw"))
);
const RejectedWithdraw = Loadable(
  lazy(() => import("views/utilities/withdraw/rejected-withdraw"))
);

const User = Loadable(lazy(() => import("views/utilities/user")));
const UserRefer = Loadable(lazy(() => import("views/utilities/user/user-refer")));
const EditUser = Loadable(lazy(() => import("views/utilities/user/edit-user")));
const Wallet = Loadable(lazy(() => import("views/utilities/wallet")));
const Commission = Loadable(lazy(() => import("views/utilities/commission")));
const EditCommission = Loadable(lazy(() => import("views/utilities/commission/edit-commission")));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },

    {
      children: [
        {
          path: "products",
          element: <Product />,
        },
        {
          path: "add-product",
          element: <AddProduct />,
        },
        {
          path: "edit-product/:id",
          element: <EditProduct />,
        },
      ],
    },
    {
      children: [
        {
          path: "qr",
          element: <QR />,
        },
        {
          path: "add-qr",
          element: <AddQR />,
        },
        {
          path: "history-qr",
          element: <HistoryQR />,
        },
        {
          path: "edit-qr/:id",
          element: <EditQR />,
        },
        {
          path: "view-qr/:id",
          element: <ViewQR />,
        },
      ],
    },

    {
      children: [
        {
          path: "withdraw",
          element: <AllWithdraw />,
        },

        {
          path: "withdraw-request",
          element: <WithdrawRequest />,
        },
        {
          path: "edit-withdraw/:id",
          element: <EditWithdraw />,
        },
        {
          path: "rejected-withdraw",
          element: <RejectedWithdraw />,
        },
      ],
    },
    {
      children: [
        {
          path: "wallet",
          element: <Wallet />,
        },
      ],
    },
    {
      children: [
        {
          path: "commission",
          element: <Commission />,
        },
        {
          path: "edit-commission/:id",
          element: <EditCommission />,
        },
      ],
    },
    {
      children: [
        {
          path: "users",
          element: <User />,
        },
        {
          path: "edit-user/:id",
          element: <EditUser />,
        },
        {
          path: "user-refer/:id",
          element: <UserRefer />,
        },
      ],
    },
  ],
};

export default MainRoutes;
