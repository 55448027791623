import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product: [],
};

//internally using immer lib (can create mutable state)
export const productSlice = createSlice({
  name: "productData",
  initialState,
  reducers: {
   
    updateAllProduct: (state, action) => {
      state.product = action.payload;
    },
  },
});

// this is for dispatch
export const {  updateAllProduct } = productSlice.actions;

// this is for configureStore
export default productSlice.reducer;
