// assets
import {
  IconShoppingCart,
  IconQrcode,
  IconCashOff,
  IconUsers,
  IconWallet,
  IconPercentage,
} from "@tabler/icons";

// constant
const icons = {
  IconShoppingCart,
  IconQrcode,
  IconCashOff,
  IconUsers,
  IconWallet,
  IconPercentage,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "products",
      title: "Products",
      icon: icons.IconShoppingCart,
      type: "collapse",
      children: [
        {
          id: "all-products",
          title: "All Products",
          type: "item",
          url: "/products",
          breadcrumbs: true,
        },
        {
          id: "add-product",
          title: "Add Product",
          type: "item",
          url: "/add-product",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "qr",
      title: "QR",
      icon: icons.IconQrcode,
      type: "collapse",
      children: [
        {
          id: "all-QR",
          title: "All QR",
          type: "item",
          url: "/qr",
          breadcrumbs: true,
        },
        {
          id: "history-qr",
          title: "History QR",
          type: "item",
          url: "/history-qr",
          breadcrumbs: true,
        },
        {
          id: "add-qr",
          title: "Add QR",
          type: "item",
          url: "/add-qr",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "withdraw",
      title: "Withdraw Details",
      icon: icons.IconCashOff,
      type: "collapse",
      children: [
        {
          id: "all-withdraw",
          title: "All Withdraw",
          type: "item",
          url: "/withdraw",
          breadcrumbs: true,
        },
        {
          id: "withdraw-request",
          title: "Withdraw Request",
          type: "item",
          url: "/withdraw-request",
          breadcrumbs: true,
        },
        {
          id: "rejected-withdraw",
          title: "Rejected Withdraw",
          type: "item",
          url: "/rejected-withdraw",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "user-wallet",
      title: "User Wallet",
      icon: icons.IconWallet,
      type: "item",
      url: "/wallet",
    },
    {
      id: "commission",
      title: "Commission",
      icon: icons.IconPercentage,
      type: "item",
      url: "/commission",
    },
    {
      id: "all-user",
      title: "User",
      icon: icons.IconUsers,
      type: "item",
      url: "/users",
    },
  ],
};

export default utilities;
