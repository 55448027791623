import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qr: [],
};

//internally using immer lib (can create mutable state)
export const qrSlice = createSlice({
  name: "qrData",
  initialState,
  reducers: {
   
    updateAllQR: (state, action) => {
      state.qr = action.payload;
    },
  },
});

// this is for dispatch
export const {  updateAllQR } = qrSlice.actions;

// this is for configureStore
export default qrSlice.reducer;
